import { differenceInCalendarDays, formatISO } from 'date-fns'
import {
  createRef,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import async from 'react-select/dist/declarations/src/async/index'
import pathConfiguration from 'src/configuration/path'
import configuration from '~/configuration'
import { IRouterWithID } from '~/core/@types/global'
import {
  AGENCY_TENANT,
  TAB_CANDIDATES,
  TAB_COMPANIES
} from '~/core/constants/enum'
import { openAlert } from '~/core/ui/AlertDialog'
import { AvatarGroup } from '~/core/ui/AvatarGroup'
import { Checkbox } from '~/core/ui/Checkbox'
import { IconButton } from '~/core/ui/IconButton'
import IconWrapper from '~/core/ui/IconWrapper'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import {
  isHTML,
  truncateTextWithDot,
  removeHTMLTags
} from '~/core/utilities/common'
import {
  defaultFormatDate,
  defaultFormatDateTime
} from '~/core/utilities/format-date'
import { limitedMemberCanAction } from '~/core/utilities/permission'
import UpdateCompanyTaskMutation from '~/lib/features/agency/companies/graphql/update-company-task-mutation'
import { TASKS_TAB } from '~/lib/features/agency/companies/utilities/company-detail-enum'
import {
  TaskActions,
  TaskFormInActiveType,
  TaskItemType
} from '~/lib/features/tasks/types'
import { TASK_STATUS } from '~/lib/features/tasks/utilities/enum'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import LongContentDisplay from '../LongContentDisplay'
import TaskForm from './TaskForm'

const renderDueDate = (dueDate: string, t: any) => {
  const diffInDays = differenceInCalendarDays(new Date(dueDate), new Date())

  return dueDate ? (
    <div className="flex flex-none items-center">
      <IconWrapper
        size={14}
        name="Calendar"
        className={`mr-1.5 ${
          diffInDays <= -2 ? 'text-red-500' : 'text-gray-600'
        }`}
      />
      <TypographyText
        className={`text-xs ${
          diffInDays <= -2 ? 'text-red-500' : 'text-gray-700'
        }`}>
        {diffInDays === -1
          ? `${t('label:yesterday')}`
          : diffInDays === 0
          ? `${t('label:today')}`
          : diffInDays === 1
          ? `${t('label:tomorrow')}`
          : defaultFormatDateTime(dueDate ? new Date(dueDate) : new Date())}
      </TypographyText>
    </div>
  ) : null
}

const TaskCard: FC<{
  task: TaskItemType
  applicantId?: IRouterWithID
  formInActive?: TaskFormInActiveType
  setFormInActive: Dispatch<SetStateAction<TaskFormInActiveType | undefined>>
  action: TaskActions
  refetchList: (previousTypeAction?: 'delete' | 'edit') => void
  configShow: {
    dueDate: boolean
    isDrawer: boolean
    assignees: boolean
    showSelectTaskRelated?: boolean
  }
  showAllAssigneeList?: boolean
  onCheckTask?: (params: { checked: boolean; taskId: number }) => void
  companyId?: number
  ownedAction: { edit: boolean; delete: boolean }
  configTruncate?: {
    content?: boolean
  }
}> = ({
  task,
  applicantId,
  formInActive,
  setFormInActive,
  action,
  refetchList,
  configShow,
  showAllAssigneeList,
  onCheckTask,
  companyId,
  ownedAction,
  configTruncate = {}
}) => {
  const { t } = useTranslation()
  const TaskRef = createRef<HTMLDivElement>()
  const [showEditForm, setShowEditForm] = useState<boolean>(false)
  const [isRunAnimationFadeOut, setIsRunAnimationFadeOut] =
    useState<boolean>(false)
  const { user, currentRole, setToast } = useBoundStore()
  const showDueDate = configShow.dueDate && task.dueDate
  const showAssignees = configShow.assignees && task.assignees
  const isDrawer = configShow.isDrawer
  const isShowData = task?.applicant || task?.profile || task?.company
  const currentTab = useBoundStore((state) => state.currentTabRelated)

  const { isCompanyKind: isAgency } = useDetectCompanyWithKind({
    kind: AGENCY_TENANT
  })
  const {
    trigger: triggerUpdateCompanyTask,
    isLoading: isTriggerUpdateCompanyTaskLoading
  } = useSubmitCommon(UpdateCompanyTaskMutation)

  const onShowEditForm = useCallback(() => {
    setShowEditForm(true)
    setFormInActive({ type: 'edit', taskId: task.id })
  }, [task.id])

  const onHideEditForm = useCallback(() => {
    setShowEditForm(false)
    setFormInActive(undefined)
  }, [])

  const onDeleteTask = useCallback<
    (event: React.MouseEvent<HTMLElement>) => void
  >(
    (_) => {
      openAlert({
        isPreventAutoFocusDialog: false,
        className: 'w-[480px]',
        title: `${t('common:modal:delete_task_title')}`,
        description: isHTML(task.title)
          ? t('common:modal:delete_task_description_Text', {
              title: truncateTextWithDot(removeHTMLTags(task.title), 100)
            })
          : t('common:modal:delete_task_description_Text', {
              title: truncateTextWithDot(task.title, 100)
            }),
        actions: [
          {
            label: `${t('button:cancel')}`,
            type: 'secondary',
            size: 'sm'
          },
          {
            isCallAPI: true,
            label: `${t('button:remove')}`,
            type: 'destructive',
            size: 'sm',
            onClick: async () => {
              await action.taskDeleteAction
                .deleteTask({
                  id: Number(task.id)
                })
                .then((result) => {
                  if (result.error) {
                    return catchErrorFromGraphQL({
                      error: result.error,
                      page: pathConfiguration.tasks.list,
                      setToast
                    })
                  }

                  refetchList('delete')

                  return setToast({
                    open: true,
                    type: 'success',
                    title: t('notification:task:taskDeleted'),
                    classNameConfig: {
                      viewport: 'mb-[48px]'
                    }
                  })
                })
            }
          }
        ]
      })
    },
    [task.title, task.id, action.taskDeleteAction, refetchList, setToast]
  )

  const onSubmitEditForm = async (data: FieldValues) => {
    if (action.updateTaskAction.updatingTask) return Promise.resolve()
    const formatData = {
      id: Number(task.id),
      title: data.title,
      ...(limitedMemberCanAction(currentRole?.code) && task.profile?.id
        ? { profileId: Number(task.profile.id) }
        : {}),
      ...(data.dueDate ? { dueDate: formatISO(data.dueDate) } : {}),
      ...(applicantId ? { applicantId: Number(applicantId) } : {}),
      assigneeIds: data.assigneeIds.map((assignee: ISelectOption) =>
        Number(assignee.value)
      ),
      ...(currentTab === TAB_COMPANIES && data?.relatedIds
        ? { companyId: Number(data.relatedIds?.value) }
        : { profileId: Number(data.relatedIds?.value) })
    }
    {
      isAgency && currentTab === TAB_COMPANIES
        ? triggerUpdateCompanyTask(formatData).then((result) => {
            if (result.error) {
              return catchErrorFromGraphQL({
                error: result.error,
                page: pathConfiguration.tasks.list,
                setToast
              })
            }
            const { companyTasksUpdate } = result.data
            if (companyTasksUpdate?.task?.id) {
              setToast({
                open: true,
                type: 'success',
                title: t('notification:changesSaved'),
                classNameConfig: {
                  viewport: 'mb-[48px]'
                }
              })
              refetchList('edit')
              setShowEditForm(false)
            }
            return true
          })
        : action.updateTaskAction.updateTask(formatData).then((result) => {
            setShowEditForm(false)
            if (result.error) {
              catchErrorFromGraphQL({
                error: result.error,
                page: pathConfiguration.tasks.list,
                setToast
              })
            }

            refetchList('edit')

            setToast({
              open: true,
              type: 'success',
              title: t('notification:changesSaved'),
              classNameConfig: {
                viewport: 'mb-[48px]'
              }
            })
          })
    }
  }

  useEffect(() => {
    if (formInActive?.type === 'add') setShowEditForm(false)
  }, [formInActive?.type])

  useEffect(() => {
    if (formInActive?.type === 'edit' && formInActive?.taskId !== task.id)
      setShowEditForm(false)
  }, [formInActive?.taskId, formInActive?.type, task.id])

  return (
    <>
      {showEditForm &&
      (!formInActive ||
        (formInActive.type === 'edit' && formInActive.taskId === task.id)) ? (
        <TaskForm
          showSelectTaskRelated={configShow.showSelectTaskRelated}
          applicantId={applicantId}
          companyId={companyId}
          autoFocus
          candidateProfileId={
            showAllAssigneeList ? task.profile?.id : undefined
          }
          formInActive={formInActive}
          isEdited
          isExpand={
            formInActive?.type === 'edit' && formInActive.taskId === task.id
          }
          setIsExpand={(value) =>
            setFormInActive(
              value ? { type: 'edit', taskId: task.id } : undefined
            )
          }
          onClose={onHideEditForm}
          onDelete={onDeleteTask}
          onFinish={onSubmitEditForm}
          isCallResetAfterSubmitForm={false}
          defaultValue={{
            title: task.title,
            assigneeIds: task.assignees.map((assignee) => ({
              avatar: assignee.avatarVariants?.thumb?.url,
              avatarVariants: assignee.avatarVariants,
              supportingObj: {
                name: assignee.fullName,
                defaultColour: assignee.defaultColour
              },
              value: String(assignee.id)
            })),
            dueDate: task.dueDate ? new Date(task.dueDate) : undefined,
            relatedIds: task?.profile
              ? {
                  value: String(task?.profile.id),
                  supportingObj: {
                    name: task?.profile?.fullName || ''
                  }
                }
              : task?.company
              ? {
                  value: String(task?.company?.id),
                  supportingObj: {
                    name: task?.company?.name || ''
                  }
                }
              : undefined,
            tab: task?.profile
              ? TAB_CANDIDATES
              : task?.company
              ? TAB_COMPANIES
              : undefined
          }}
          isShowEveryoneOption={false}
        />
      ) : (
        <div
          ref={TaskRef}
          className={cn(
            'group relative mr-0.5 flex space-x-3 opacity-100 transition-opacity delay-500 duration-500 ease-in-out',
            isRunAnimationFadeOut ? 'hidden' : ''
          )}
          style={{
            display: isRunAnimationFadeOut ? 'none' : ''
          }}>
          <Checkbox
            isChecked={task.status === TASK_STATUS.done}
            onCheckedChange={(e) => {
              if (onCheckTask) {
                onCheckTask({
                  checked: e.target.checked,
                  taskId: Number(task.id)
                })
              }

              // Local animation fade-out
              if (TaskRef?.current) {
                TaskRef.current.style.opacity = '0'
                TaskRef.current?.addEventListener('transitionend', () => {
                  setIsRunAnimationFadeOut(true)
                })
              }
            }}
            size="sm"
          />

          <div className="space-y-1.5">
            <Tooltip
              classNameConfig={{
                content: !!configTruncate?.content ? '' : 'hidden'
              }}
              content={<p dangerouslySetInnerHTML={{ __html: task.title }} />}>
              <p
                className={cn(
                  'text-sm text-gray-900',
                  !!configTruncate?.content ? 'line-clamp-1' : ''
                )}
                dangerouslySetInnerHTML={{ __html: task.title }}
              />
            </Tooltip>

            {task?.comments?.length ? (
              <div className="rounded border border-solid border-gray-100 bg-gray-50 px-3 py-1.5">
                <LongContentDisplay
                  isHTML
                  className="text-sm text-gray-900"
                  content={task?.comments
                    .map((item) => item.content)
                    .join(', ')}
                  limitLines={2}
                  textButtonProps={{
                    size: 'md',
                    underline: false
                  }}
                />
              </div>
            ) : null}
            <div className="flex items-center">
              {showDueDate && renderDueDate(task.dueDate, t)}

              {isDrawer && isShowData ? (
                <>
                  {showDueDate ? (
                    <div className="mx-2 h-0.5 w-0.5 flex-none rounded-full bg-gray-400" />
                  ) : null}
                  {!!task?.company ? (
                    <div className="flex max-w-[200px] items-center">
                      <IconWrapper
                        size={14}
                        name="Building"
                        className="mr-1.5 flex-none text-gray-600"
                      />
                      <Tooltip content={task?.company?.name}>
                        <a
                          href={`${configuration.path.agency.companyDetail(
                            task.company.id
                          )}?tab=${TASKS_TAB}`}
                          target="_blank">
                          <TypographyText className="line-clamp-1 text-xs text-gray-700">
                            {task.company.name}
                          </TypographyText>
                        </a>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex max-w-[200px] items-center">
                      <IconWrapper
                        size={14}
                        name="User"
                        className="mr-1.5 flex-none text-gray-600"
                      />
                      <Tooltip
                        content={
                          task?.applicantId
                            ? task?.applicant?.profile?.fullName
                            : task.profile?.fullName
                        }>
                        <a
                          href={configuration.path.candidates.detail(
                            task?.applicantId
                              ? task?.applicant?.profile?.id
                              : task.profile?.id,
                            task?.applicantId
                          )}
                          target="_blank">
                          <TypographyText className="line-clamp-1 text-xs text-gray-700">
                            {task?.applicantId ? (
                              <>
                                {(task.applicant?.profile?.fullName || '')
                                  .length > 40
                                  ? `${task.applicant?.profile?.fullName.slice(
                                      0,
                                      30
                                    )}...`
                                  : task.applicant?.profile?.fullName}
                              </>
                            ) : (
                              <>
                                {(task.profile?.fullName || '').length > 40
                                  ? `${task.profile?.fullName.slice(0, 30)}...`
                                  : task.profile?.fullName}
                              </>
                            )}
                          </TypographyText>
                        </a>
                      </Tooltip>
                    </div>
                  )}
                </>
              ) : null}

              {(showDueDate || (isDrawer && isShowData)) && (
                <div className="mx-2 h-0.5 w-0.5 rounded-full bg-gray-400" />
              )}
              {showAssignees && (
                <AvatarGroup
                  size="2xs"
                  tooltipAlign="center"
                  source={task?.assignees.map((item) => ({
                    id: item.id,
                    alt: item.fullName,
                    src: item.avatarVariants?.thumb?.url,
                    defaultColour: item.defaultColour,
                    tooltip: `${item.fullName}`
                  }))}
                />
              )}
            </div>
          </div>

          {task?.assignees
            ?.map((item) => String(item.id))
            .includes(String(user?.id)) ? (
            <div className="absolute right-0 top-0 flex rounded border border-gray-100 bg-white p-0.5 opacity-0 group-hover:opacity-100">
              {ownedAction?.edit ? (
                <>
                  {task?.comments?.length === 0 ? (
                    <Tooltip content={t('tooltip:edit')}>
                      <IconButton
                        className="mr-1"
                        iconMenus="Edit3"
                        onClick={onShowEditForm}
                        size="xs"
                        type="secondary"
                      />
                    </Tooltip>
                  ) : null}
                </>
              ) : null}

              {ownedAction?.delete ? (
                <Tooltip content={t('tooltip:delete')}>
                  <IconButton
                    className="!border-0"
                    iconMenus="Trash2"
                    onClick={onDeleteTask}
                    size="xs"
                    type="secondary-destructive"
                  />
                </Tooltip>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export default TaskCard
