import { gql } from 'urql'

const QueryMatchedFields = gql`
  query ($id: Int!, $recommendationTarget: String) {
    jobsShow(id: $id, recommendationTarget: $recommendationTarget) {
      id
      recommendationMatchedFields
    }
  }
`

export default QueryMatchedFields
