import { createContext, FC, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import withPermissionFeatureProvider from 'src/hoc/with-permission-feature'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import InterviewFeedbackView from '~/components/Interview/Feedback/InterviewFeedbackView'
import { openAlert } from '~/core/ui/AlertDialog'
import { Drawer } from '~/core/ui/Drawer'
import {
  ACTIONS_PERMISSIONS,
  canAccessFeature,
  PERMISSIONS_LIST
} from '~/core/utilities/feature-permission'
import { pushStateBrowser } from '~/core/utilities/is-browser'
import useInterviewStore from '~/lib/features/interviews/store'
import { useDiscardRouteChange } from '~/lib/hooks/use-discard-route-change'
import useBoundStore from '~/lib/store'

const InterviewFeedbackDrawer: FC = () => {
  const { t } = useTranslation()
  const {
    openInterviewFeedbackDrawer,
    setOpenInterviewFeedbackDrawer,
    interviewDrawer,
    setInterviewDrawer
  } = useInterviewStore()
  const { setRefetchMyList } = useBoundStore()
  const { anyChangesForm, setAnyChangesFrom } = useDiscardRouteChange()
  const handleOnClose = () => {
    if (anyChangesForm) {
      openAlert({
        isPreventAutoFocusDialog: false,
        className: 'w-[480px]',
        title: `${t('common:modal:discard_unsaved_changes_title')}`,
        description: `${t('common:modal:discard_unsaved_changes_description')}`,
        actions: [
          {
            label: `${t('button:keepEditing')}`,
            type: 'secondary',
            size: 'sm'
          },
          {
            label: `${t('button:discard')}`,
            type: 'destructive',
            size: 'sm',
            onClick: () => handleActionClose()
          }
        ]
      })
    } else {
      handleActionClose()
    }
  }

  const handleActionClose = () => {
    setAnyChangesFrom(false)
    setRefetchMyList(true)
    setOpenInterviewFeedbackDrawer(false)
    if (interviewDrawer?.returnUrl) {
      pushStateBrowser({
        state: {},
        unused: '',
        url: interviewDrawer.returnUrl
      })
    }
    setInterviewDrawer({
      id: undefined,
      returnUrl: undefined,
      openForm: false,
      jobIkitEvalId: undefined
    })
  }

  const drawerContainerRef = useRef<HTMLDivElement>(null)
  return (
    <InterviewFeedbackContext.Provider value={{ drawerContainerRef }}>
      <Drawer
        position="right"
        size="full"
        drawerClassName="!max-w-[calc(100vw_-_60px)]"
        contentRef={drawerContainerRef}
        open={openInterviewFeedbackDrawer}
        onClose={handleOnClose}
        onEscapeKeyDown={handleOnClose}>
        <InterviewFeedbackView
          isDrawer
          id={interviewDrawer?.id}
          onClose={handleOnClose}
          setAnyChangesFrom={setAnyChangesFrom}
        />
      </Drawer>
    </InterviewFeedbackContext.Provider>
  )
}

export const useGetUploadDrawerRef = () => {
  const uploadContext = useContext(InterviewFeedbackContext)
  return uploadContext.drawerContainerRef?.current
}

const InterviewFeedbackContext = createContext<{
  drawerContainerRef?: React.RefObject<HTMLDivElement>
}>({})

export default withPermissionFeatureProvider(
  {
    checkAccessPermission: canAccessFeature,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job_ikit_evaluation
        .keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  },
  withQueryClientProvider(InterviewFeedbackDrawer)
)
