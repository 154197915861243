const CreateTaskAgencyMutation = `
  mutation (
    $title: String!,
    $profileId: Int,
    $applicantId: Int,
    $dueDate: ISO8601DateTime,
    $assigneeIds: [Int!]!,
    $companyId: Int
  ) {
    tasksCreate(
      input: {
        title: $title,
        profileId: $profileId,
        applicantId: $applicantId,
        dueDate: $dueDate,
        assigneeIds: $assigneeIds,
        companyId: $companyId
      }
    ) {
      task {
        id
      }
    }
  }
`

export default CreateTaskAgencyMutation
