'use client'

import { cva } from 'class-variance-authority'
import {
  createRef,
  KeyboardEvent,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import SelectWrapper, {
  ActionMeta,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupHeadingProps,
  InputActionMeta,
  MultiValue,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  NoticeProps,
  OptionProps,
  Options,
  SingleValue,
  SingleValueProps,
  components
} from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { Avatar, AvatarSize } from '~/core/ui/Avatar'
import { Badge } from '~/core/ui/Badge'
import { Checkbox } from '~/core/ui/Checkbox'
import { Dot, IDotColorProps } from '~/core/ui/Dot'
import { CloseCircleFill } from '~/core/ui/FillIcons'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { InputChips } from '~/core/ui/InputChips'
import { SuggestionChipsItem } from '~/core/ui/SuggestionChips'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn, removeAccents, truncateTextWithDot } from '~/core/ui/utils'

interface ObjectLiteral {
  [key: string]: any
}

interface TypeKeySearch {
  name: string
  description: string
}

const selectRootVariants = cva(
  'flex items-center justify-center space-x-[9px] flex-1',
  {
    variants: {
      size: {
        lg: 'min-h-[42px]',
        md: 'min-h-[32px]',
        sm: 'min-h-[32px]',
        xs: 'min-h-[24px]'
      }
    },
    defaultVariants: {
      size: 'lg'
    }
  }
)

const selectOptionBoxVariants = cva('', {
  variants: {
    option: {
      default: '',
      option: 'rounded'
    },
    group: {
      default: '',
      group: 'pl-2 py-[6px]'
    },
    focus: {
      default: '',
      focus: 'bg-gray-50 dark:bg-gray-700'
    },
    size: {
      default: '',
      'size-lg-option': 'py-[9px] pl-2 pr-[10px]',
      'size-sm-md-option': 'py-[6px] pl-2 pr-[10px]'
    },
    optionNoFocus: {
      default: '',
      'option-no-focus': 'dark:bg-gray-800'
    },
    subs: {
      default: '',
      subs: 'pl-5'
    }
  },
  defaultVariants: {
    size: 'default',
    option: 'default',
    group: 'default',
    focus: 'default',
    optionNoFocus: 'default',
    subs: 'default'
  }
})

const getSize = (size?: string) => {
  let className = ''
  switch (size) {
    case 'xs':
      className = '!min-h-[24px]'
      break

    case 'sm':
      className = '!min-h-[32px]'
      break

    case 'md':
      className = '!min-h-[38px]'
      break

    default:
      className = '!min-h-[42px]'
      break
  }

  return className
}

const inputClass = ({
  classNameOverride = {},
  size = 'lg'
}: {
  classNameOverride?: SelectClassControl
  size?: SelectSizeProps
  isMulti?: boolean
}) => {
  return cn(
    'cursor-pointer overflow-hidden',
    classNameOverride.input,
    size === 'lg' && 'text-base',
    size !== 'lg' && 'text-sm'
  )
}

const inputPlaceholderClass = ({
  classNameOverride = {},
  size = 'lg',
  isMulti = false
}: {
  classNameOverride?: SelectClassControl
  size?: SelectSizeProps
  isMulti?: boolean
}) => {
  return cn(
    classNameOverride.placeholder,
    'text-gray-600 dark:text-gray-700',
    size === 'lg' && 'text-base',
    size !== 'lg' && 'text-sm'
    // isMulti === true && 'pl-[5px]'
  )
}

const indicatorsContainerClass = ({
  classNameOverride = {},
  isMulti = false
}: {
  classNameOverride?: SelectClassControl
  isMulti?: boolean
}) => {
  return cn(
    classNameOverride.indicatorsContainer,
    /*!isMulti && for bug https://app.clickup.com/t/3721736/FATS-727  */ 'pr-[10px] pl-2'
  )
}

const valueContainerClass = ({
  classNameOverride = {},
  isMulti = false,
  hasValue
}: {
  classNameOverride?: SelectClassControl
  isMulti?: boolean
  hasValue?: boolean
}) => {
  return cn(
    !isMulti ? 'pl-3' : 'pr-0 pl-3 space-x-2 space-x-reverse',
    hasValue && isMulti ? 'pl-1.5 py-1.5' : '',
    `${classNameOverride.valueContainer || ''}`
  )
}

const multiValueClass = ({
  classNameOverride = {},
  size = 'lg'
}: {
  classNameOverride?: SelectClassControl
  size?: SelectSizeProps
}) => {
  return cn(
    classNameOverride.multiValue,
    'mr-2 space-x-1 relative',
    size === 'lg' && 'my-1',
    size === 'md' && 'my-[2px]',
    size === 'sm' && 'my-[2px]'
  )
}

const rootSelectClass = ({
  classNameOverride = {},
  destructive,
  hasValue = false,
  size = 'lg',
  variant = 'default'
}: {
  classNameOverride?: SelectClassControl
  destructive?: boolean
  hasValue?: boolean
  size?: SelectSizeProps
  variant?: SelectVariantProps
}) => {
  const variantGhost = variant === 'ghost'
  const controlClass = ({ isDisabled = false, isFocused = false }) => {
    return cn(
      'min-w-full',
      variantGhost === false
        ? `${classNameOverride.bordered || 'border-solid border'}`
        : classNameOverride.ghostRootSelect || 'hover:bg-gray-50',
      'w-full rounded outline-none group',
      isDisabled &&
        variantGhost === false &&
        'border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-800',
      destructive &&
        isDisabled === false &&
        'border-red-300 dark:border-red-700 dark:bg-gray-900',
      !destructive &&
        isDisabled === false &&
        'border-gray-300 dark:border-gray-600 dark:bg-gray-900',
      isFocused &&
        !destructive &&
        'border-solid border shadow-select dark:shadow-dark-select dark:bg-gray-900 dark:border-primary-700 border-primary-300',
      isFocused &&
        destructive &&
        'border-solid border shadow-error dark:shadow-dark-error dark:bg-gray-900 dark:border-red-700 border-red-300',
      [getSize(size)],
      classNameOverride.control
    )
  }

  return {
    clearIndicator: () => 'cursor-pointer',
    container: () => classNameOverride.container || '',
    control: ({ isDisabled = false, isFocused = false }) =>
      controlClass({ isDisabled, isFocused }),
    group: () => `${classNameOverride.group || ''} py-2`,
    groupHeading: () => `${classNameOverride.groupHeading || ''} uppercase`,

    indicatorsContainer: ({ isMulti = false }) =>
      indicatorsContainerClass({ classNameOverride, isMulti }),
    input: () => inputClass({ classNameOverride, size }),
    loadingIndicator: () => `${classNameOverride.loadingIndicator || ''} p-2`,
    loadingMessage: () => `${classNameOverride.loadingMessage || ''} py-2 px-3`,
    menu: () =>
      `${
        classNameOverride.menu || ''
      } bg-white min-w-[320px] dark:bg-gray-800 rounded shadow-ats dark:shadow-dark-ats !z-20 my-1 p-1`,
    menuList: () => classNameOverride.menuList || '',
    menuPortal: () => classNameOverride.menuPortal || '',
    multiValue: ({ data }: any) => {
      return ` ${data.chipHidden ? '!hidden' : ''} ${multiValueClass({
        classNameOverride,
        size
      })}`
    },
    multiValueRemove: () =>
      `${
        classNameOverride.multiValueRemove || ''
      } cursor-pointer absolute right-0 top-0 bottom-0 w-6 opacity-0 z-10`,
    noOptionsMessage: () =>
      `${classNameOverride.noOptionsMessage || ''} py-2 px-3`,
    placeholder: ({ isMulti = false }) =>
      inputPlaceholderClass({ classNameOverride, size, isMulti }),
    singleValue: () => classNameOverride.singleValue || '',
    valueContainer: ({ isMulti = false }) =>
      valueContainerClass({ classNameOverride, isMulti, hasValue })
  }
}

const rootDropdownClass = ({
  classNameOverride = {},
  destructive,
  size = 'lg',
  variant = 'default'
}: {
  classNameOverride?: SelectClassControl
  destructive?: boolean
  size?: SelectSizeProps
  variant?: SelectVariantProps
}) => {
  const controlClass = () => {
    return cn(
      `${classNameOverride.control || 'min-w-full'}`,
      `${classNameOverride.bordered || 'border-solid border-b'}`,
      'border-gray-100 dark:border-gray-700 w-full dark:bg-gray-800',
      getSize(size)
    )
  }

  return {
    clearIndicator: () => 'cursor-pointer',
    container: () => classNameOverride.container || '',
    control: () => controlClass(),
    group: () => `${classNameOverride.group || ''} py-2`,
    groupHeading: () => `${classNameOverride.groupHeading || ''} uppercase`,

    indicatorsContainer: ({ isMulti = false }) =>
      indicatorsContainerClass({ classNameOverride, isMulti }),
    input: () => inputClass({ classNameOverride, size }),
    loadingIndicator: () => `${classNameOverride.loadingIndicator || ''} p-2`,
    loadingMessage: () => `${classNameOverride.loadingMessage || ''} py-2 px-3`,
    menu: () =>
      `${
        classNameOverride.menu || ''
      } bg-white dark:bg-gray-800 !z-20 rounded-b !relative p-1`,
    menuList: () => classNameOverride.menuList || '',
    menuPortal: () => classNameOverride.menuPortal || '',
    multiValue: () => multiValueClass({ classNameOverride, size }),
    multiValueRemove: () =>
      `${
        classNameOverride.multiValueRemove || ''
      } cursor-pointer absolute right-0 top-0 bottom-0 w-6 opacity-0 z-10`,
    noOptionsMessage: () =>
      `${classNameOverride.noOptionsMessage || ''} py-2 px-3`,
    placeholder: ({ isMulti = false }) =>
      inputPlaceholderClass({ classNameOverride, size, isMulti }),
    singleValue: () => classNameOverride.singleValue || '',
    valueContainer: ({ isMulti = false }) =>
      valueContainerClass({ classNameOverride, isMulti })
  }
}

type SelectSizeProps = 'lg' | 'md' | 'sm' | 'xs'
type SelectVariantProps = 'default' | 'ghost'

interface SelectClassControl {
  clearIndicator?: string
  container?: string
  control?: string
  bordered?: string
  dropdownIndicator?: string
  group?: string
  groupHeading?: string
  indicatorsContainer?: string
  indicatorSeparator?: string
  input?: string
  loadingIndicator?: string
  loadingMessage?: string
  menu?: string
  menuList?: string
  menuPortal?: string
  multiValue?: string
  multiValueLabel?: string
  multiValueRemove?: string
  noOptionsMessage?: string
  option?: string
  placeholder?: string
  singleValue?: string
  valueContainer?: string
  ghostRootSelect?: string
}

interface SelectProps {
  variant?: SelectVariantProps
  type?: 'default' | 'dot-leading'
  autoFocus?: boolean
  backspaceRemovesValue?: boolean
  controlShouldRenderValue?: boolean
  hideSelectedOptions?: boolean
  closeMenuOnSelect?: boolean
  isClearable?: boolean
  menuIsOpen?: boolean
  tabSelectsValue?: boolean
  defaultOptions?: Array<ISelectOption> | boolean
  isGhostTypeAndShowDropdownIcon?: boolean
  /**
   * If cacheOptions is truthy, then the loaded data will be cached. The cache
   * will remain until `cacheOptions` changes value.
   */
  cacheOptions?: any
  loadOptions?: (
    inputValue: string,
    callback: (options: Array<ISelectOption>) => void
  ) => Promise<Array<ISelectOption>> | void

  onMenuOpen?: () => void
  /** Handle the menu closing */
  onMenuClose?: () => void
  /** Fired when the user scrolls to the top of the menu */
  onMenuScrollToTop?: (event: WheelEvent | TouchEvent) => void
  /** Fired when the user scrolls to the bottom of the menu */
  onMenuScrollToBottom?: (event: WheelEvent | TouchEvent) => void

  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
  inputValue?: string
  async?: boolean
  creatable?: boolean
  inputId?: string
  unstyled?: boolean
  classNameOverride?: SelectClassControl
  options?: Array<ISelectOption>
  isDisabled?: boolean
  isLoading?: boolean
  onChange: (
    newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null,
    actionMeta: ActionMeta<ISelectOption>
  ) => void
  value?: ISelectOption | Array<ISelectOption>
  isSearchable?: boolean
  isMulti?: boolean
  isMultiShowType?: 'default' | 'ava-leading' | 'dot-leading' | 'icon-leading'
  isMultiComponent?: 'InputChips' | 'SuggestionChipsItem'
  showDropdownIndicator?: boolean
  showGhostDropdownIndicator?: boolean
  showClearIndicator?: boolean
  isDropdown?: boolean
  configSelectOption?: SelectOptionProps
  classNameOption?: {
    [key: string]: string
  }

  size?: SelectSizeProps
  destructive?: boolean
  placeholder?: string
  menuPlacement?: 'top' | 'auto' | 'bottom'
  menuPosition?: 'absolute' | 'fixed'
  maxLength?: number

  ref?: any

  hideControl?: Boolean
  detectAvatarFromOption?: boolean
  customerDropdownHeader?: ReactElement
  isValidNewOption?: (inputSearch?: string) => boolean
  filterOption?: (
    option: FilterOptionOption<ISelectOption>,
    inputValue: string
  ) => boolean
  formatCreateLabel?: (val?: string) => void
  onKeyDown?: (e?: any) => void
  searchBy?: Array<string>
  disableSearch?: boolean
  noOptionsMessage?: () => ReactNode
}

interface SelectOptionProps {
  data?: ISelectOption // | GroupBase<ISelectOption>
  isSelected?: boolean
  isFocused?: boolean
  isDisabled?: boolean
  isOption?: boolean
  isHeading?: boolean
  isMulti?: boolean
  isMultiShowType?: 'default' | 'ava-leading' | 'dot-leading' | 'icon-leading'
  isMultiComponent?: 'InputChips' | 'SuggestionChipsItem'
  isHideAvatarSelectedOption?: boolean
  isParentId?: boolean

  option?: 'radio' | 'checkbox'
  size?: SelectSizeProps
  dot?: boolean
  logo?: boolean
  logoVariants?: ILogoAndAvatarVariants
  avatar?: boolean
  avatarVariants?: ILogoAndAvatarVariants
  flag?: boolean
  icon?: boolean

  supportingText?: Array<string>
  tooltipDisabled?: string
  classNameOption?: {
    [key: string]: string
  }
  defaultAvatar?: boolean
}

interface ILogoAndAvatarVariants {
  url: string
  medium?: {
    url: string
  }
  thumb?: {
    url: string
  }
}

interface ISelectOption {
  id?: string
  value: string
  parentId?: string
  __isNew__?: boolean
  label?: string

  supportingObj?: {
    name: string
    description?: string
    descriptionHelpName?: string
    helpName?: string
    badge?: string
    defaultColour?: string
    shortDescription?: string
    shortName?: string
    nameRequired?: boolean
  }
  dot?: IDotColorProps
  dotDescription?: string
  logo?: string
  logoVariants?: ILogoAndAvatarVariants
  avatar?: string
  avatarVariants?: ILogoAndAvatarVariants
  avatarSize?: AvatarSize
  flag?: string | ReactNode
  icon?: LucideIconName
  disabled?: boolean
  tooltipDisabled?: string
  isHideAvatar?: boolean
  selected?: boolean
  chipHidden?: boolean,
  subordinates?: {
    id?: string
    name?: string
    parentId?: number
    membersCount?: number
  }[]
}

interface IPromiseSearchOption {
  search: string
  page?: number
  limit?: number
  sorting?: {
    id: string
  }
}

const SelectOption = ({
  data,
  isSelected,
  isFocused,
  isDisabled,
  isHeading,
  isOption,
  isMulti,
  isMultiComponent = 'InputChips',
  isMultiShowType = 'default',
  isHideAvatarSelectedOption = false,
  size,
  option = 'radio',
  dot,
  logo,
  logoVariants,
  avatar,
  defaultAvatar = true,
  avatarVariants,
  flag,
  icon,
  supportingText = ['name'],
  classNameOption,
  isParentId,
  tooltipDisabled
}: SelectOptionProps) => {
  const shouldShowDescription = supportingText.includes('description')
  const shouldShowHelpName = supportingText.includes('helpName')
  const shouldShowBadge = supportingText.includes('badge')
  const truncateDescription = supportingText.includes('shortDescription')
  const shouldShowShortName = supportingText.includes('shortName')
  const shouldShowDescriptionHelpName = supportingText.includes(
    'descriptionHelpName'
  )
  const renderedImage = ({ hideAvatar = false }: { hideAvatar?: boolean }) => {
    const sizeIcon = data?.avatarSize
      ? data?.avatarSize
      : shouldShowDescription && !Object.keys(data || {}).includes('avatar')
      ? size === 'lg'
        ? 'lg'
        : 'md'
      : size === 'lg'
      ? 'sm'
      : isMulti
      ? '2xs'
      : 'xs'
    if (!hideAvatar && (avatar || avatarVariants || logo)) {
      return (
        <>
          <Avatar
            defaultAvatar={defaultAvatar}
            color={data?.supportingObj?.defaultColour}
            alt={data?.supportingObj?.name}
            size={sizeIcon}
            src={data?.avatarVariants?.thumb?.url || data?.avatar}
            shape={logo ? 'rounded' : 'circular'}
          />
        </>
      )
    }
    if (icon) {
      return <IconWrapper size={size === 'lg' ? 20 : 16} name={data?.icon} />
    }
    if (dot) {
      if (data?.dotDescription) {
        return (
          <Tooltip classNameAsChild="-mt-1" content={data?.dotDescription}>
            <Dot size="xl" color={data?.dot} />
          </Tooltip>
        )
      }

      return (
        <div className="-mt-1">
          <Dot size="xl" color={data?.dot} />
        </div>
      )
    }
    if (flag) {
      return (
        <p className="text-2xl text-gray-600 dark:text-gray-300">
          {data?.flag}
        </p>
      )
    }

    return null
  }

  const renderedOption = () => {
    const sizeName = size === 'lg' ? 'text-base' : 'text-sm'

    if (data?.__isNew__) return null
    return (
      <Tooltip
        content={isDisabled && tooltipDisabled ? tooltipDisabled : ''}
        classNameConfig={{
          content: isDisabled && tooltipDisabled ? '' : 'hidden'
        }}
      >
        <div className="flex items-center justify-between space-x-3">
          {option === 'checkbox' && !data?.__isNew__ ? (
            <Checkbox
              isDisabled={isDisabled}
              isChecked={isSelected}
              size="sm"
              className={cn("mt-0", isSelected ? classNameOption?.['checkboxSelectedClassName'] : '')}
            />
          ) : null}
          <div className="flex-1">
            <div className={cn('flex space-x-2', dot ? '' : 'items-center')}>
              {renderedImage({ hideAvatar: data?.isHideAvatar })}
              <div className="flex-1 whitespace-break-spaces break-all">
                <div className="flex items-center space-x-2">
                  {shouldShowShortName && data?.supportingObj?.shortName ? (
                    <Tooltip content={data?.supportingObj?.shortName}>
                      <p
                        className={`${sizeNew} ${
                          isSelected ? 'font-medium' : 'font-normal'
                        } line-clamp-1 ${
                          isDisabled
                            ? 'text-gray-400 dark:text-gray-700'
                            : 'text-gray-900 dark:text-gray-200'
                        }`}
                      >
                        {data?.supportingObj?.shortName}
                      </p>
                    </Tooltip>
                  ) : (
                    <p
                      className={`${sizeNew} ${
                        isSelected ? 'font-medium' : 'font-normal'
                      } line-clamp-1 ${
                        isDisabled
                          ? 'text-gray-400 dark:text-gray-700'
                          : 'text-gray-900 dark:text-gray-200'
                      }`}
                    >
                      {data?.supportingObj?.name}{' '}
                      {data?.supportingObj?.nameRequired ? (
                        <span className="font-sm text-red-500">*</span>
                      ) : null}
                    </p>
                  )}
                  {shouldShowHelpName && (
                    <p
                      className={`${
                        shouldShowDescription ? 'text-sm' : sizeName
                      } line-clamp-1 whitespace-nowrap text-gray-600 dark:text-gray-400`}
                    >
                      {data?.supportingObj?.helpName}
                    </p>
                  )}

                  {shouldShowBadge && data?.supportingObj?.badge && (
                    <Badge color="green" classNameText="w-max">
                      {data?.supportingObj?.badge}
                    </Badge>
                  )}
                </div>

                {shouldShowDescription ? (
                  <div className={'flex items-center'}>
                    <p
                      className={cn(
                        'text-xs text-gray-600 dark:text-gray-400',
                        data?.supportingObj?.descriptionHelpName
                          ? 'flex whitespace-nowrap'
                          : 'break-keep'
                      )}
                    >
                      {data?.supportingObj?.descriptionHelpName
                        ? truncateTextWithDot(
                            data?.supportingObj?.description || ''
                          )
                        : data?.supportingObj?.description}
                    </p>
                    {shouldShowDescriptionHelpName ? (
                      <div className="flex items-center">
                        {data?.supportingObj?.description &&
                        data?.supportingObj?.descriptionHelpName ? (
                          <div className="mx-2 h-0.5 w-0.5 rounded bg-gray-400" />
                        ) : null}
                        <p className="line-clamp-1 text-xs text-gray-600 dark:text-gray-400">
                          {data?.supportingObj?.descriptionHelpName}
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {truncateDescription && (
                  <div className="flex flex-1">
                    <Tooltip content={data?.supportingObj?.shortDescription}>
                      <p className="line-clamp-1 block max-w-[600px] overflow-hidden text-ellipsis whitespace-nowrap break-keep text-xs text-gray-600 dark:text-gray-400">
                        {data?.supportingObj?.shortDescription}
                      </p>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>

          {option === 'radio' && isSelected ? (
            <div className="min-h-[20px] min-w-[20px]">
              <IconWrapper name="Check" size={20} />
            </div>
          ) : null}
        </div>
      </Tooltip>
    )
  }

  const renderedValue = () => {
    const sizeName = size === 'lg' ? 'text-base' : 'text-sm'
    if (isMulti) {
      if (isMultiComponent === 'SuggestionChipsItem') {
        return (
          <SuggestionChipsItem
            label={data?.__isNew__ ? data?.label : data?.supportingObj?.name}
            size={size === 'xs' ? 'sm' : size}
            isRemovable
          />
        )
      }

      //@ts-ignore
      return !data?.options ? (
        <InputChips
          label={data?.__isNew__ ? data?.label : data?.supportingObj?.name}
          colorAvatar={
            data?.__isNew__ ? undefined : data?.supportingObj?.defaultColour
          }
          altAvatar={data?.__isNew__ ? data?.label : data?.supportingObj?.name}
          srcAvatar={data?.avatar}
          type={isMultiShowType}
          color={data?.dot}
          size={size === 'xs' ? 'sm' : size}
          removable={!data?.disabled}
        />
      ) : null
    }

    return (
      <div className="flex items-center">
        <div className={cn('flex space-x-2', dot ? '' : 'items-center')}>
          {renderedImage({ hideAvatar: isHideAvatarSelectedOption })}

          <div className="flex-1 whitespace-break-spaces break-all">
            <div className="flex items-center space-x-2">
              <p
                className={cn(
                  'line-clamp-1',
                  sizeName,
                  isDisabled
                    ? 'text-gray-400 dark:text-gray-700'
                    : 'text-gray-900 dark:text-gray-200',
                  classNameOption?.value
                )}
              >
                {data?.__isNew__ ? data?.label : data?.supportingObj?.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const variantCondition = () => {
    if (size === 'lg' && isOption && !isHeading) return 'size-lg-option'
    if (['sm', 'md', 'xs', undefined].includes(size) && isOption && !isHeading)
      return 'size-sm-md-option'
    return 'default'
  }

  const sizeNew = size === 'lg' ? 'text-base' : 'text-sm'
  return (
    <div
      className={cn(
        selectOptionBoxVariants({
          option: isOption ? 'option' : 'default',
          group: isHeading ? 'group' : 'default',
          focus: isFocused ? 'focus' : 'default',
          optionNoFocus: isOption && !isFocused ? 'option-no-focus' : 'default',
          subs: isParentId ? 'subs' : 'default',
          size: variantCondition()
        })
      )}
    >
      {isHeading ? (
        <p className="text-xs font-medium text-gray-600 dark:text-gray-200">
          {data?.label}
        </p>
      ) : null}

      {isOption && !isHeading ? (
        <>
          {renderedOption()}
          {data?.__isNew__ ? (
            <p className={`${sizeNew} text-gray-900 dark:text-gray-200`}>
              {data?.label}
            </p>
          ) : null}
        </>
      ) : (
        renderedValue()
      )}
    </div>
  )
}

const Select = ({
  async = false,
  creatable = false,
  inputId = 'react-select',
  unstyled = true,
  classNameOverride = {},
  showDropdownIndicator = true,
  showGhostDropdownIndicator = true,
  showClearIndicator = true,
  isDropdown = false,
  configSelectOption,
  classNameOption,
  isClearable = true,
  size = 'lg',
  destructive = false,
  ref,
  menuPlacement = 'auto',
  maxLength = 50,
  variant = 'default',
  isGhostTypeAndShowDropdownIcon = false,
  isValidNewOption = () => true,
  formatCreateLabel,
  onKeyDown,
  searchBy = [],
  disableSearch = false,
  ...props
}: SelectProps) => {
  const selectRef = createRef<any>()
  const [optionsState, setOptionsState] = useState(props.options)
  useEffect(() => {
    setOptionsState(props.options)
  }, [props?.options])
  const componentHasValue = useMemo<boolean>(
    () => (Array.isArray(props.value) ? props.value.length > 0 : !!props.value),
    [props.value]
  )

  const configClass = isDropdown
    ? rootDropdownClass({ classNameOverride, destructive, size, variant })
    : rootSelectClass({
        classNameOverride,
        destructive,
        size,
        variant,
        hasValue: componentHasValue
      })
  const dropdownIndicatorIcon = props.isDisabled
    ? 'text-gray-400 dark:text-gray-400'
    : 'text-gray-600 dark:text-gray-400'

  const renderSelect = () => {
    const ClearIndicator = (
      props: ClearIndicatorProps<ISelectOption, true>
    ) => {
      if (isGhostTypeAndShowDropdownIcon) {
        return (
          <components.ClearIndicator {...props} className="my-ignore-action">
            <div
              className={cn(
                variant === 'ghost' && showGhostDropdownIndicator === true
                  ? 'hidden group-focus-within:flex group-focus-within:items-center'
                  : ''
              )}
            >
              <div className="block">
                <IconWrapper
                  name="ChevronDown"
                  size={size === 'lg' ? 18 : 16}
                  className={dropdownIndicatorIcon}
                />
              </div>
            </div>
          </components.ClearIndicator>
        )
      }

      if (showClearIndicator === false) return null

      if (variant === 'ghost') {
        return (
          <components.ClearIndicator {...props} className="my-ignore-action">
            <div
              className={cn(
                variant === 'ghost' && showGhostDropdownIndicator === true
                  ? 'hidden group-focus-within:flex group-focus-within:items-center'
                  : ''
              )}
            >
              <div className="hidden group-hover:block">
                <CloseCircleFill
                  size={size === 'lg' ? 18 : 16}
                  className="fill-gray-400 dark:fill-gray-500"
                />
              </div>

              <div className="block group-hover:hidden">
                <IconWrapper
                  name="ChevronDown"
                  size={size === 'lg' ? 18 : 16}
                  className={dropdownIndicatorIcon}
                />
              </div>
            </div>
          </components.ClearIndicator>
        )
      }

      return (
        <components.ClearIndicator {...props} className="my-ignore-action">
          <div className="hidden group-hover:block">
            <CloseCircleFill
              size={size === 'lg' ? 18 : 16}
              className="fill-gray-400 dark:fill-gray-500"
            />
          </div>
          <div className="block group-hover:hidden">
            <IconWrapper
              name="ChevronDown"
              size={size === 'lg' ? 18 : 16}
              className={dropdownIndicatorIcon}
            />
          </div>
        </components.ClearIndicator>
      )
    }

    const DropdownIndicator = (
      props: DropdownIndicatorProps<ISelectOption, true>
    ) => {
      if (
        (props.selectProps.isClearable &&
          props.hasValue &&
          props.isDisabled === false) ||
        showDropdownIndicator === false
      ) {
        return null
      }

      if (variant === 'ghost' && showGhostDropdownIndicator === true) {
        return (
          <components.DropdownIndicator {...props}>
            <div
              className={cn(
                variant === 'ghost'
                  ? 'hidden group-focus-within:flex group-focus-within:items-center'
                  : ''
              )}
            >
              <IconWrapper
                name="ChevronDown"
                size={size === 'lg' ? 18 : 16}
                className={dropdownIndicatorIcon}
              />
            </div>
          </components.DropdownIndicator>
        )
      }

      return (
        <components.DropdownIndicator {...props}>
          <IconWrapper
            name="ChevronDown"
            size={size === 'lg' ? 18 : 16}
            className={dropdownIndicatorIcon}
          />
        </components.DropdownIndicator>
      )
    }

    const LoadingMessage = (props: NoticeProps<ISelectOption>) => {
      const fontSize = size === 'lg' ? 'text-base' : 'text-sm'
      const iconSize = size === 'lg' ? 18 : 16
      return (
        <div
          className={cn(
            selectRootVariants({
              size
            }),
            classNameOverride
          )}
          {...props.innerProps}
        >
          <IconWrapper
            name="Loader2"
            size={iconSize}
            className="animate-spin text-gray-400 dark:text-white"
          />
          <p className={`${fontSize} text-gray-600 dark:text-gray-300`}>
            {classNameOverride?.loadingMessage
              ? classNameOverride?.loadingMessage
              : ' Loading'}
          </p>
        </div>
      )
    }

    const NoOptionsMessage = (props: NoticeProps<ISelectOption>) => {
      const fontSize = size === 'lg' ? 'text-base' : 'text-sm'
      return (
        <div
          className={cn(
            selectRootVariants({
              size
            }),
            classNameOverride
          )}
          {...props.innerProps}
        >
          <p className={`${fontSize} text-gray-600 dark:text-gray-300`}>
            {classNameOverride?.noOptionsMessage
              ? classNameOverride?.noOptionsMessage
              : ' No options'}
          </p>
        </div>
      )
    }

    // close ScrollArea because it's break react-select function `onMenuScrollToBottom`
    // const MenuList = (props: MenuListProps<ISelectOption>) => {
    //   return (
    //     <ScrollArea
    //       className="max-h-[300px]"
    //       viewportClassName="w-full max-h-[300px] rounded-[inherit]">
    //       {props.children}
    //     </ScrollArea>
    //   )
    // }

    const MultiValueRemove = (
      otherProps: MultiValueRemoveProps<ISelectOption, false>
    ) => {
      if (props.isDisabled || otherProps?.data?.disabled) return null

      return <components.MultiValueRemove {...otherProps} />
    }

    const SingleValue = ({
      children,
      ...otherProps
    }: SingleValueProps<ISelectOption>) => {
      return (
        <components.SingleValue {...otherProps}>
          <SelectOption
            isParentId={undefined}
            size={size}
            data={otherProps.data}
            isSelected={false}
            isFocused={false}
            isDisabled={props.isDisabled}
            isOption={false}
            isHeading={false}
            isMulti={props.isMulti}
            isMultiShowType={props.isMultiShowType}
            isMultiComponent={props.isMultiComponent}
            {...configSelectOption}
            classNameOption={classNameOption}
          />
        </components.SingleValue>
      )
    }

    const MultiValueLabel = ({
      children,
      ...otherProps
    }: MultiValueGenericProps<ISelectOption>) => {
      return (
        <components.MultiValueLabel {...otherProps}>
          <SelectOption
            isParentId={undefined}
            size={size}
            data={otherProps.data}
            isSelected={false}
            isFocused={false}
            isDisabled={props.isDisabled}
            isOption={false}
            isHeading={false}
            isMulti={props.isMulti}
            isMultiShowType={props.isMultiShowType}
            isMultiComponent={props.isMultiComponent}
            {...configSelectOption}
            classNameOption={classNameOption}
          />
        </components.MultiValueLabel>
      )
    }

    const GroupHeading = ({
      children,
      ...otherProps
    }: GroupHeadingProps<ISelectOption>) => {
      return (
        <components.GroupHeading {...otherProps}>
          <SelectOption
            isParentId={undefined}
            size={size}
            data={{
              value: '',
              ...otherProps.data
            }}
            isSelected={false}
            isFocused={false}
            isDisabled={props.isDisabled}
            isOption={true}
            isHeading={true}
            isMulti={props.isMulti}
            isMultiShowType={props.isMultiShowType}
            isMultiComponent={props.isMultiComponent}
            {...configSelectOption}
            classNameOption={classNameOption}
          />
        </components.GroupHeading>
      )
    }

    const Option = ({
      children,
      ...otherProps
    }: OptionProps<ISelectOption>) => {
      return (
        <components.Option
          {...otherProps}
          isDisabled={!!otherProps.data.disabled}
        >
          <SelectOption
            isParentId={!!otherProps.data?.parentId}
            size={size}
            data={otherProps.data}
            isSelected={otherProps.isSelected || !!otherProps.data.selected}
            isFocused={otherProps.isFocused && !otherProps.data.disabled}
            isDisabled={props.isDisabled || !!otherProps.data.disabled}
            tooltipDisabled={otherProps.data?.tooltipDisabled}
            isOption={true}
            isHeading={false}
            isMulti={props.isMulti}
            isMultiShowType={props.isMultiShowType}
            isMultiComponent={props.isMultiComponent}
            isHideAvatarSelectedOption={otherProps.data.isHideAvatar}
            {...configSelectOption}
            classNameOption={classNameOption}
          />
        </components.Option>
      )
    }

    const ownComponents = {
      DropdownIndicator,
      ClearIndicator,
      IndicatorSeparator: () => null,
      LoadingMessage,
      NoOptionsMessage,
      // MenuList,
      MultiValueRemove,

      SingleValue,
      MultiValueLabel,
      GroupHeading,
      Option,
      ...(props.hideControl
        ? {
            Control: () => <></>
          }
        : {})
    }

    const filterOption = (
      option: { data: ISelectOption },
      inputValue: string
    ) => {
      let data = option?.data?.supportingObj?.name || ''

      if (searchBy.length) {
        data = searchBy
          .map((val) => {
            if (option?.data?.supportingObj) {
              const { supportingObj } = option.data
              return supportingObj[val as keyof TypeKeySearch]
            }
            return []
          })
          .filter((item) => !!item)
          .join(' ')
      }

      if (async) return !!option?.data
      if (option?.data?.__isNew__) return !!option?.data

      return (
        removeAccents(data.toLowerCase()).indexOf(
          removeAccents(inputValue.toLowerCase())
        ) >= 0
      )
    }

    let mergedOptions: ObjectLiteral = {
      ...props,
      ref: selectRef,
      options: optionsState,
      isOptionDisabled: (option: ISelectOption) => option.disabled,
      inputId,
      unstyled,
      menuPlacement,
      isClearable: !!isClearable,
      formatCreateLabel: formatCreateLabel
      // props.isClearable === false ? false : props.isMulti ? false : true
    }

    if (disableSearch) {
      mergedOptions = {
        ...mergedOptions,
        menuIsOpen: false,
        onKeyDown: onKeyDown
      }
    }

    const preventSpacingToSelectFistOption = (
      e: KeyboardEvent<HTMLDivElement>
    ) => {
      if (e.keyCode === 32 && !selectRef?.current?.props?.inputValue) {
        e.preventDefault()
      }
    }

    if (async && creatable) {
      return (
        <AsyncCreatableSelect
          {...mergedOptions}
          components={ownComponents}
          classNames={configClass}
          onKeyDown={(e) => {
            preventSpacingToSelectFistOption(e)
          }}
          isValidNewOption={(inputValue, value, options) => {
            const duplicateWithOption = (options as ISelectOption[]).find(
              (option) =>
                removeAccents(
                  option?.supportingObj?.name?.toLowerCase() || ''
                ) === removeAccents(inputValue.toLowerCase())
            )
            const duplicateWithSelectedOption = (value as ISelectOption[]).find(
              (option) =>
                removeAccents(
                  (
                    option?.supportingObj?.name || option?.label
                  )?.toLowerCase() || ''
                ) === removeAccents(inputValue.toLowerCase())
            )
            return (
              !!inputValue.trim() &&
              !duplicateWithOption &&
              !duplicateWithSelectedOption &&
              isValidNewOption(inputValue)
            )
          }}
        />
      )
    }

    if (creatable) {
      return (
        <CreatableSelect
          {...mergedOptions}
          {...(!mergedOptions?.filterOption
            ? {
                filterOption: filterOption
              }
            : {})}
          components={ownComponents}
          classNames={configClass}
          isValidNewOption={(inputValue, value, options) => {
            const duplicateWithOption = (options as ISelectOption[]).find(
              (option) =>
                removeAccents(
                  option?.supportingObj?.name?.toLowerCase() || ''
                ) === removeAccents(inputValue.toLowerCase())
            )
            const duplicateWithSelectedOption = (value as ISelectOption[]).find(
              (option) =>
                removeAccents(
                  (
                    option?.supportingObj?.name || option?.label
                  )?.toLowerCase() || ''
                ) === removeAccents(inputValue.toLowerCase())
            )
            return (
              !!inputValue.trim() &&
              !duplicateWithOption &&
              !duplicateWithSelectedOption &&
              isValidNewOption(inputValue)
            )
          }}
        />
      )
    }

    // !!Notice: Select with call API to load more didn't use Async select component for rendering
    // if (async) {
    //   return (
    //     // @ts-expect-error
    //     <AsyncSelect
    //       {...mergedOptions}
    //       components={ownComponents}
    //       classNames={configClass}
    //       onKeyDown={(e) => {
    //         preventSpacingToSelectFistOption(e)
    //       }}
    //     />
    //   )
    // }

    const preventDeleteKey: KeyboardEventHandler<HTMLDivElement> = (e) => {
      if (!showClearIndicator && !isDropdown && e.keyCode === 8) {
        e.preventDefault()
      }
    }

    return (
      <SelectWrapper
        {...mergedOptions}
        {...(!mergedOptions?.filterOption
          ? {
              filterOption: filterOption
            }
          : {})}
        components={ownComponents}
        classNames={configClass}
        onKeyDown={(e) => {
          preventDeleteKey(e)
          preventSpacingToSelectFistOption(e)
        }}
      />
    )
  }

  return renderSelect()
}

export { Select, SelectOption }
export type {
  IPromiseSearchOption,
  ISelectOption,
  SelectClassControl,
  SelectOptionProps,
  SelectProps,
  SelectSizeProps,
  SelectVariantProps
}
