import { useCallback, useEffect, useMemo, useState } from 'react'
import configuration from '~/configuration'
import { IUserInformation } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { IPromiseSearchOption } from '~/core/ui/Select'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import QueryCompaniesList from '../../agency/companies/graphql/query-companies-list'
import { CompanyItemListingType } from '../../agency/companies/types/company-detail'
import { IProfileListType } from '../../candidates/types'
import { DESC_SORTING } from '../../candidates/utilities/enum'
import { useInfinityGraphPage } from '../../jobs/hooks/use-infinity-graph-page'
import DeleteTaskMutation from '../graphql/delete-task-mutation'
import QueryRelateProfilesList from '../graphql/query-profiles-list'
import QueryTasksList from '../graphql/query-tasks-list'
import UpdateTaskMutation from '../graphql/update-task-mutation'
import { ITasksManagementFilter } from '../types'
import { trimObjectProps } from '../utilities/common'
import { TAB_KEYS } from '../utilities/enum'

export const TABS_CACHE_KEYS: {
  [key: string]: {
    [key: string]: string
  }
} = {
  assigned: {
    default: 'tasks-management-list-assigned',
    completed: 'tasks-management-list-assigned-completed'
  },
  my: {
    default: 'tasks-management-list-my',
    completed: 'tasks-management-list-my-completed'
  }
}

const useTasksManagement = ({
  isCalendar,
  user
}: {
  isCalendar: boolean
  user?: IUserInformation
}) => {
  const { clientGraphQL } = useContextGraphQL()
  const [filterValue, onChangeFilter] = useState<ITasksManagementFilter>()

  const [isLoading, setIsLoading] = useState(false)
  const queryKey = useMemo(
    () =>
      filterValue?.tab
        ? TABS_CACHE_KEYS?.[filterValue?.tab]?.[
            filterValue?.filterBy || 'default'
          ]
        : TABS_CACHE_KEYS.my.default,
    [filterValue?.filterBy, filterValue?.tab]
  )

  const taskPaging = useInfinityGraphPage({
    queryDocumentNote: QueryTasksList,
    getVariable: useCallback(
      (page) => {
        const { tab, profileId, filterBy, assigneesIds } = filterValue || {}
        return trimObjectProps({
          limit: !isCalendar ? configuration.defaultPageSize : undefined,
          page: !isCalendar ? page : undefined,
          tab: isCalendar ? undefined : tab || TAB_KEYS[0].value,
          ...(!isCalendar ? { filterBy: filterBy || 'uncompleted' } : {}),
          ...(profileId ? { profileId: profileId } : {}),
          ...(isCalendar ? { dueDateExisting: isCalendar } : {}),
          fromDatetime: filterValue?.fromDatetime,
          toDatetime: filterValue?.toDatetime,
          ...(assigneesIds && assigneesIds.length > 0
            ? {
                assigneesIds: assigneesIds.map((item) => Number(item.value))
              }
            : {})
        })
      },
      [filterValue]
    ),
    getPageAttribute: (_lastGroup, groups) => ({
      totalCount: _lastGroup?.tasksList?.metadata?.totalCount,
      pageLength: groups?.[0]?.tasksList?.collection?.length
    }),
    queryKey: [
      queryKey,
      (filterValue?.assigneesIds || [])
        ?.map((assignee) => assignee?.value)
        .join(', ')
    ],
    enabled: true
  })
  const promiseProfileOptions = (params = {} as IPromiseSearchOption) => {
    setIsLoading(false)
    return clientGraphQL
      .query(QueryRelateProfilesList, {
        ...params,
        sorting: { id: DESC_SORTING }
      })
      .toPromise()
      .then((result: IResponseContextResult<IProfileListType>) => {
        if (result.error) {
          return {
            metadata: {
              totalCount: configuration.defaultAsyncLoadingOptions
            },
            collection: []
          }
        }
        const { profilesList } = result.data
        const collection = profilesList?.collection || []
        const metadata = profilesList?.metadata || {}
        const cloneData = collection.map((item) => {
          return {
            value: item.id,
            avatar: item.avatarVariants?.thumb?.url,
            avatarVariants: item.avatarVariants,
            supportingObj: {
              name: item.fullName,
              description: item.email,
              defaultColour: item.defaultColour
            }
          }
        })
        setIsLoading(true)
        return {
          metadata: { ...cloneData, totalCount: metadata.totalCount },
          collection: cloneData
        }
      })
  }
  const promiseCompanyListOption = (params = {} as IPromiseSearchOption) => {
    setIsLoading(false)
    return new Promise<any>((resolve) => {
      return clientGraphQL
        .query(QueryCompaniesList, {
          ...params
        })
        .toPromise()
        .then((result: IResponseContextResult<CompanyItemListingType>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { companiesList } = result.data
          const collection = companiesList?.collection || []
          const metadata = companiesList?.metadata || {}

          const cloneData = collection.map((item: CompanyItemListingType) => {
            return {
              value: item.id,
              logoVariants: item.logoVariants,
              avatarVariants: item.logoVariants,
              supportingObj: {
                name: item.name
              }
            }
          })
          setIsLoading(true)
          return resolve({ metadata, collection: cloneData })
        })
    })
  }
  useEffect(() => {
    taskPaging.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  useEffect(() => {
    user?.timezone &&
      onChangeFilter({
        assigneesIds: [
          {
            value: String(user?.id),
            supportingObj: {
              name: user?.fullName || '',
              defaultColour: user?.defaultColour
            },
            avatar: user.avatarVariants?.thumb?.url,
            avatarVariants: user?.avatarVariants
          }
        ]
      })
  }, [
    user?.avatarVariants,
    user?.defaultColour,
    user?.fullName,
    user?.id,
    user?.timezone
  ])

  const { trigger: deleteTask, isLoading: deletingTask } =
    useSubmitCommon(DeleteTaskMutation)
  const { trigger: updateTask, isLoading: updatingTask } =
    useSubmitCommon(UpdateTaskMutation)

  return {
    taskPaging,
    filterControl: useMemo(
      () => ({ value: filterValue, onChange: onChangeFilter }),
      [filterValue]
    ),
    action: {
      taskDeleteAction: {
        deleteTask: (args: { id: number; profileId?: number }) =>
          deleteTask(args),
        deletingTask
      },
      updateTaskAction: {
        updateTask: (args: {
          id: number
          title?: string
          profileId?: number
          dueDate?: string
          assigneeIds?: Array<number>
        }) => updateTask(args),
        updatingTask,
        updateTaskStatus: (args: {
          id: number
          status?: string
          title?: string
          assigneeIds?: Array<number>
        }) => updateTask(args)
      }
    },
    promiseProfileOptions,
    promiseCompanyListOption,
    isLoading
  }
}

export default useTasksManagement
